.newTicket .RuleCardContainer{
	padding-left: 5px;
}

.newTicket .RuleCardContainer .RuleCard {
	text-align: start;
	transition: all 0.5s;
}

.newTicket .RuleCardContainer .RuleCard:hover {
	box-shadow: 0px 0px 5px var(--ticket-main-color);
	cursor: pointer;
}

.newTicket .RuleCardContainer.RuleSelected .RuleCard {
	box-shadow: 0px 0px 5px var(--ticket-main-color);
}

.newTicket .RuleCardContainer:not(.RuleDisabled) {
	order: -1;
}

.newTicket .RuleCardContainer.RuleDisabled {
	cursor: not-allowed;
}

.newTicket .RuleCardContainer.RuleDisabled:last-of-type{
	padding-right: 0;
}

.newTicket .RuleCardContainer.RuleDisabled .RuleCard {
	pointer-events: none;
	opacity: 0.5;
}

.newTicket .RuleCard .title {
	text-align: start;
	font-size: 22px;
	font-weight: 600;
}
.newTicket .RuleCard .sharesInfo {
	text-align: start;
}
.newTicket .RuleCard .card-body > .card-title {
	font-weight: 400;
}
