$color_1: black;

.navBarInfo {
	background: rgb(241, 241, 241);
	transition: all 0.5s;
	max-height: 100px;

	@media (max-width: 300px) {
		min-height: 50px;
	}

	filter: drop-shadow(0px 0px 3px gray);

	.greeting {
		margin: 0;
		color: $color_1;
		font-weight: 400;
		font-size: 1.25rem;
	}

	.image {
		opacity: 0.2;
	}

	.navbar-brand {
		img {
			height: 3rem;
		}

		@media (max-width: 576px) {
			margin-right: 0;

			img {
				height: 2.5rem;
			}
		}

		@media (max-width: 400px) {
			margin-right: 0;

			img {
				height: 1.5rem;
			}
		}
	}

	.icon {
		color: var(--bs-body-color)
	}
}

.navBarInfo.toggled {
	max-height: 0;
	overflow-y: hidden;
}

@media (max-width: 576px) {
	.navBarInfo {
		.greeting {
			font-size: 1.1rem;
		}

		z-index: 1500;
	}
}