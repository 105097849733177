.navBarTotal {
	z-index: 1;
	position: sticky;
	top: 45px;
	background-color: var(--blue-main) !important;
	filter: drop-shadow(0px 0px 5px black);
}

.total {
	font-size: 1.5rem;
	font-weight: bold;
	text-align: center;
	color: white;
	opacity: 0.87;
}
@media (max-width: 576px) {
	.changePassword {
		background: white;
	}
	.total {
		font-size: 1rem;
	}
}
