.languageSelector {
	
	.dropdown-item{
		&.active,&:active {
			background-color: gray;
		}
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: .5rem;
		padding-bottom: .5rem;
	}
	.dropdown-menu {
		min-width: 100%;
		@media (max-width: 576px) {
			min-width: unset !important;
		}
	}
	.btn {
		color: black !important;
		font-size: 1rem;
		border-radius: .25rem!important;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.bg-none {
		background-color: transparent !important;
		border-color: transparent !important;
	}
}
