$color_1: var(--ticket-main-color);
$color_2: var(--ticket-secondary-color);
$color_3: black;
$color_4: red;
$color_5: green;
$background-color_1: transparent;
$background-color_2: var(--ticket-secondary-color);
$background-color_3: var(--ticket-main-color);
$background-color_4: var(--blue-main);
$background-color_5: white;
$border-color_1: red;
$border-color_2: green;

.newTicket {
	.label {
		font-size: 1.25rem;
		font-weight: 400;

		.numberContainer {
			border: var(--ticket-main-color) 1px solid;
			border-radius: 50px;
			height: 2rem;
			width: 2rem;
			font-size: 1rem;
			margin-right: 0.5em;
		}

		.number {
			width: 100%;
			text-align: center;
			font-size: 1.25rem;
			color: $color_1;
		}
	}

	.typeContainer {
		.type {
			background-color: $background-color_1;
			border: transparent;

			.header {
				text-align: center;
				color: $color_1;
				background-color: $background-color_2;
				border-radius: 10px;

				&:hover {
					box-shadow: 0px 0px 5px var(--ticket-main-color);
				}

				img {
					display: inline-block;
					width: 50%;
				}
			}

			.footer {
				background-color: $background-color_1;
				border: transparent;
				width: 100%;
				text-align: center;
			}
		}

		.typeSelected {
			background-color: $background-color_1;
			border: transparent;

			.header {
				text-align: center;
				background-color: $background-color_3;
				color: $color_2;
				border-radius: 10px;
				box-shadow: 0px 0px 5px var(--ticket-main-color);

				img {
					display: inline-block;
					width: 50%;
				}
			}

			.footer {
				background-color: $background-color_1;
				border: transparent;
				width: 100%;
				text-align: center;
			}
		}
	}

	.accordion-item {
		background: transparent;
		z-index: 1;
	}

	.accordion-button {
		background: transparent;

		&:after {
			background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23082044'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
		}

		&:not(.collapsed) {
			color: $color_1;
			background-color: $background-color_1;
			box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
		}
	}

	.card-title {
		font-size: 1rem;
	}

	.card-text {
		font-size: 13px;
	}

	.sellDescription {
		font-size: 17px;
	}
}

.accordion-button {
	&:not(.collapsed) {
		&::after {
			background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23082044'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
			transform: rotate(-180deg);
		}
	}
}

.navBarAvailableCash {
	position: unset;
	top: 0px;
	z-index: 1009;
	background-color: $background-color_4 !important;
	filter: drop-shadow(0px 0px 5px black);
}

.currency-input {
	padding: 1rem;
}

.confirmationModal {
	.header {
		background-color: $background-color_5 !important;
	}

	.body {
		background-color: $background-color_5 !important;
		border-radius: 2rem 2rem 0 0;
	}

	.footer {
		background-color: $background-color_5 !important;
	}

	.title {
		color: $color_3 !important;
		text-align: center;
		font-weight: 300;
		font-size: 40px;
	}

	.descriptionIconContainer {
		font-size: 3rem;
		width: 1.5em;
		height: 1.5em;
		border: 3px solid;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.descriptionIconContainer.red {
		border-color: $border-color_1;
	}

	.descriptionIconContainer.green {
		border-color: $border-color_2;
	}

	.icon {
		text-align: center;
		font-size: 3rem;
	}

	.icon.red {
		color: $color_4 !important;
	}

	.icon.green {
		color: $color_5 !important;
	}

	.listedInfo {
		color: $color_3 !important;
		font-weight: 300;
		text-align: start;
		font-size: 1rem;
	}

	.emphasis {
		font-weight: 500;
	}

	.heading {
		color: $color_3 !important;
		text-align: center;
		font-weight: 300;
		font-size: 1rem;
	}
}

.FundsAdministration {
	.editResult {
		animation: ease growOpacity 1s;
	}

	.editForm {
		animation: ease growOpacity 1s;
	}
}

@media (max-width: 576px) {
	.overflow-overlay {
		overflow: overlay;
	}

	.overflow-auto {
		overflow: auto;
	}

	.FundCardsContainer {
		scroll-snap-type: x mandatory;
	}

	.newTicket {
		.FundCardContainer {
			scroll-snap-align: center;
		}

		.submitBtn {
			transform: translateX(20%);
		}
	}
}

@media (min-width: 576px) {
	.overflow-sm-hidden {
		overflow: hidden;
	}
}


@media (max-width: 576px) {
	.newTicket {

		.accordion-body,
		.accordion-button {
			padding-left: .25em;
			padding-right: .25em
		}

		.label {
			font-size: .9rem;
			font-weight: 400;
			padding-bottom: 0;

			.number {
				font-size: .9rem;
			}

			.numberContainer {
				height: 1.25rem;
				width: 1.25rem;
				font-size: 1rem;
			}
		}

		.FundCard,.RuleCard {

			.card-header,
			.card-body {
				padding: .5em;
			}
			.card-title span{
				white-space: nowrap;
			}

			.title {
				font-size: 1rem;
			}
		}
	}
}