.more {
    border: none;
    display: inline-block;
    font-size: .8rem;
    color: white;
    background: #171714;
    border-radius: .2em;
    padding-left: .4em;
    padding-right: .4em;
    cursor: pointer;
    font-weight: 200;
}

.select__group-heading {
    text-transform: unset !important;
}

.css-3w2yfm-ValueContainer {
    flex-wrap: nowrap !important;
}

.minWidth-unset {
    min-width: unset !important;
}

span.link-style {
    color: rgb(0, 108, 225);
    text-decoration: underline;

    &:hover {
        color: #0056b3
    }

    cursor: pointer;
}

.max-width-unset,
.max-width-unset * {
    max-width: unset !important;

    .tooltip-inner {
        max-height: 90vh;
        overflow: auto;
    }
}

.form-select {
    background-size: 1rem .8rem;
}

a.badge {
    background-color: var(--blue-main) !important;
    text-decoration: none;
    color: var(--bs-badge-color);

    &:hover {
        color: var(--bs-badge-color);
    }
}

.basic-single,
.react-select__control,
.select__control {
    min-height: calc(.375rem + 1.5rem + .375rem) !important;
}

.css-tj5bde-Svg {
    width: 1.2rem;
    height: 1.2rem;

}

.moreTooltip {

    li,
    ul {
        font-weight: 200;
        font-size: .9rem;
        margin: 0;
        text-align: start;
    }

    ul {
        padding-left: 1ch;
        list-style-type: "•"
    }

    li {
        padding-left: .5ch;
    }

    &:not(.show) {
        transition: all 0.15s linear !important;
    }

    .tooltip-inner {
        background-color: #171714;
    }
}

.header-with-border {
    position: relative;
    font-size: 1.8rem;
    font-weight: 400;
    padding: 0.5em 0;
    margin: 0.5em 0;
    border-bottom: 1px solid;

    .title {
        font-size: 1.8rem;
        padding: 0;
        margin: 0;
        font-weight: 400;
    }
}

.smallButtonStyle {
    &:not(.page-link) {
        --bs-badge-padding-x: 0.65em;
        --bs-badge-padding-y: 0.35em;
        --bs-badge-font-size: 0.75em;
        --bs-badge-font-weight: 700;
        --bs-badge-color: #fff;
        --bs-badge-border-radius: 0.375rem;
        --bs-bg-opacity: 1;
        margin-top: .25em;
        background-color: var(--blue-main) !important;
        display: inline-block;
        padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
        // font-size: var(--bs-badge-font-size);
        // font-weight: var(--bs-badge-font-weight);
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: var(--bs-badge-border-radius);
        text-decoration: none;
        color: var(--bs-badge-color);
        cursor: pointer;
        font-size: .75rem;
        &:hover {
            color: var(--bs-badge-color);
        }
    }
}