.AssetsAdministration .searchBar {
	border-radius: 2rem;
	box-shadow: 0 0 0 0 rgb(255 255 25 / 0%);
	transition: all 0.2s;
}
.AssetsAdministration .searchBar .input-group-text {
	border-radius: 2rem 0 0 2rem;
	background: rgba(255, 255, 255, 0.7);
	border-right: none;
}
.AssetsAdministration .searchBar .input-group-text .icon {
	color: #8c8a8d;
	transition: all 0.2s;
}

.AssetsAdministration .searchBar .input-group-text.left {
	border-radius: 2rem 0 0 2rem;
	border-right: none;
	margin-right: 0px !important;
}
.AssetsAdministration .searchBar .input-group-text.left .icon {
	margin-left: 0.7em;
}
.AssetsAdministration .searchBar .input-group-text.right {
	border-radius: 0 2rem 2rem 0;
	border-left: none;
	margin-left: 0px !important;
	cursor: pointer;
	transition: all 2s;
}
.AssetsAdministration .searchBar .input-group-text.right:hover .icon {
	color: rgb(250 70 0 / 50%);
}
.AssetsAdministration .searchBar .input-group-text.right .icon {
	margin-right: 0.7em;
}

.AssetsAdministration .searchBar .form-control {
	border-radius: 0 2rem 2rem 0;
	padding: 0.7em;
	border-right: none;
	border-left: none;
	background: rgba(255, 255, 255, 0.7);
}
.AssetsAdministration .notfound.searchBar {
	box-shadow: 0 0 0 0.25rem rgb(200 70 0 / 25%) !important;
}
.AssetsAdministration .searchBar.search {
	box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.AssetsAdministration .searchBar.search .form-control {
	border-right: none;
}
.AssetsAdministration .searchBar .form-control:focus {
	color: #212529;
	background: rgba(255, 255, 255, 0.7);
	border-color: rgb(206, 212, 218);
	outline: unset;
	box-shadow: unset;
}
.AssetsAdministration .verticalTop th {
	vertical-align: top;
}
.AssetsAdministration .AssetsTable {
	position: relative;
}
.AssetsAdministration .AssetsTable .id,
.AssetsAdministration .AssetsTable .Name,
.AssetsAdministration .AssetsTable .Type,
.AssetsAdministration .AssetsTable .Shares,
.AssetsAdministration .AssetsTable .FreeShares,
.AssetsAdministration .AssetsTable .SharePrice,
.AssetsAdministration .AssetsTable .Actions {
	font-size: 1rem;
}

.AssetsAdministration .AssetsTable td.Actions {
	width: 1%;
}

.AssetsAdministration .AssetRow.addAsset {
	border: none;
}
.AssetsAdministration .AssetRow.addAsset td {
	border: none;
}
.AssetsAdministration .AssetRow.addAsset .Actions {
	box-shadow: inset 0px 0px 0px 1px grey;
	background-color: rgba(0, 0, 0, 0.025);
}

.AssetsAdministration .AssetRow.addAsset .cellWithoudPadding {
	padding: 0;
	height: 0;
}
.AssetsAdministration .AssetRow .verticalCenter {
	vertical-align: middle;
}
.AssetsAdministration .AssetRow .cellWithoudPadding .form-control,
.AssetsAdministration .AssetRow .cellWithoudPadding .form-select {
	border: 0;
	border-radius: 0;
	background-color: rgb(255 255 255 / 70%);
}

@keyframes hover {
	0%,
	100% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
}

.action-status {
    position: absolute!important;
}
.action-status.hidden {
	pointer-events: none!important;
}
.esqueleton {
	position: relative!important;
	transition: max-height 0.5s,ease opacity 0.5s!important;
}
.esqueleton.hidden {
	max-height: 13rem;
	pointer-events: none!important;
}
.esqueleton.show {
	max-height: 100vw;
}
.deleteModal .hidden,
.editModal .hidden {
	position: absolute;
	left: 0;
	right: 0;
	opacity: 0;
	transition: ease opacity 0.5s;
}
.deleteModal .show,
.editModal .show {
	position: absolute;
	left: 0;
	right: 0;
	opacity: 1;
	transition: ease 0.5s opacity 0.5s;
}
.deleteModal .placeHolder,
.editModal .placeHolder {
	pointer-events: none;
	opacity: 0!important;
}
.deleteModal .header,
.editModal .header,
.deleteModal .body,
.editModal .body,
.deleteModal .footer,
.editModal .footer {
	background-color: white !important;
}
.deleteModal .body,
.editModal .body {
	border-radius: 2rem 2rem 0 0;
}

.deleteModal .title,
.editModal .title {
	color: black !important;
	text-align: center;
	font-weight: 300;

	font-size: 40px;
}
.editResult,
.editForm,
.AssetsAdministration .ViewDeleteAndCreateAssets {
	animation: ease growOpacity 1s;
}

.deleteModal .descriptionIconContainer,
.editResult .descriptionIconContainer {
	font-size: 60px;
	width: 1.5em;
	height: 1.5em;
	border: 3px solid;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.deleteModal .descriptionIconContainer.red,
.editResult .descriptionIconContainer.red {
	border-color: red;
}
.deleteModal .descriptionIconContainer.green,
.editResult .descriptionIconContainer.green {
	border-color: green;
}

.deleteModal .icon,
.editResult .icon {
	text-align: center;
	font-size: 60px;
}
.deleteModal .icon.red,
.editResult .icon.red {
	color: red !important;
}
.deleteModal .icon.green,
.editResult .icon.green {
	color: green !important;
}
.deleteModal .subTitle,
.editResult .subTitle {
	color: black !important;
	font-weight: 500;
	text-align: center;
	font-size: 1.25rem;
}
.deleteModal .heading,
.editResult .heading {
	color: black !important;
	text-align: center;
	font-weight: 300;
	font-size: 1rem;
}
.editForm .header,
.editResult .header {
	position: relative;
	font-size: 1.8rem;
	padding: 0.5em 0;
	margin: 0.5em 0;
	border-bottom: 1px solid;
}
.editForm .header .title,
.editResult .header .title {
	font-size: 1.8rem;
	font-weight: 300;
	padding: 0;
	margin: 0;
}
.editForm .header .button,
.editResult .header .button {
	font-size: 1.25rem;
	position: absolute;
	bottom: 0;
	left: 1ch;
	transform: translateY(50%);
	cursor: pointer;
	transition: 0.5s all;
}
.editForm .header .button.icon,
.editResult .header .button.icon {
	font-size: 1.25rem;
	color: var(--blue-main);
	background-color: lightgrey;
	border-radius: 50%;
}
.editForm .header .button.icon:hover,
.editResult .header .button.icon:hover {
	animation: ease bounceLeft 0.5s 2;
}

@keyframes bounceLeft {
	0%,
	100% {
		transform: translateY(50%) translateX(0%);
	}
	50% {
		transform: translateY(50%) translateX(-25%);
	}
}
.editForm .littleSpinner {
	width: 1em !important;
	height: 1em !important;
}
