.newTicket .FundCardContainer{
	padding-left: 5px;
}

.newTicket .FundCardContainer .FundCard {
	text-align: start;
	transition: all 0.5s;
}

.newTicket .FundCardContainer .FundCard:hover {
	box-shadow: 0px 0px 5px var(--ticket-main-color);
	cursor: pointer;
}

.newTicket .FundCardContainer.FundSelected .FundCard {
	box-shadow: 0px 0px 5px var(--ticket-main-color);
}

.newTicket .FundCardContainer:not(.FundDisabled) {
	order: -1;
}

.newTicket .FundCardContainer.FundDisabled {
	cursor: not-allowed;
}

.newTicket .FundCardContainer.FundDisabled:last-of-type{
	padding-right: 0;
}

.newTicket .FundCardContainer.FundDisabled .FundCard {
	pointer-events: none;
	opacity: 0.5;
}

.newTicket .FundCard .title {
	text-align: start;
	font-size: 22px;
	font-weight: 600;
}
.newTicket .FundCard .sharesInfo {
	text-align: start;
}
.newTicket .FundCard .card-body > .card-title {
	font-weight: 400;
}
