.ErrorBoundary {
    .title{
        text-align: center;
        font-size: 1.8rem;
    }
    .subtitle{
        text-align: center;
        font-size: 1rem;
    }
    .description{
        font-size: 1.8rem;
        text-align: center;
    }
    .icon{
        font-size: 6rem 
    }
}
 